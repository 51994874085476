import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ["container","pwverify1","pwverify2","pwverify3","pwverify1label","pwverify2label","pwverify3label","removesbmt"]

  connect() {
    // called when controller has connected all targets
    document.getElementById('wifiauxform').addEventListener (
    "submit",
      function (evt) {
        $("#wifiauxspinner").show();
      }
    );

    this.disableRemoveBtn();
  }

  disableRemoveBtn() {
    if (this.hasRemovesbmtTarget) {
      this.removesbmtTarget.disabled = true;
    };
  }

  enableRemoveBtn() {
    if (this.hasRemovesbmtTarget) {
      this.removesbmtTarget.disabled = false;
    };
  }

  toggleRemoveBtn() {
    if (this.hasRemovesbmtTarget) {
      if (this.removesbmtTarget.disabled==true)
        this.enableRemoveBtn();
      else
        this.disableRemoveBtn();
    };
  }

  retrieve(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
  }

  change_value() {
    var problemfound=false;

    var errorlist="";

    /* docs for this regexp can be found in the controller source */
    var pwregexp=/^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)[A-Za-z0-9#!@$%^&*()\-_+={}\[\]|\\:;"'<>,.?\/]{10,127}$/;
    if (!(pwregexp.test($("#wifiauxpw1").val()))) {
      $("#wifiauxpw1").css('background-color', '#fcc')
      this.pwverify1Target.innerHTML="✘";
      this.pwverify1Target.style.color="red";
    } else {
      this.pwverify1Target.innerHTML="✔";
      this.pwverify1Target.style.color="green";
      $("#wifiauxpw1").css('background-color', 'white')
    };

    if (!(pwregexp.test($("#wifiauxpw2").val()))) {
      $("#wifiauxpw2").css('background-color', '#fcc')
      this.pwverify2Target.innerHTML="✘";
      this.pwverify2Target.style.color="red";
    } else {
      this.pwverify2Target.innerHTML="✔";
      this.pwverify2Target.style.color="green";
      $("#wifiauxpw2").css('background-color', 'white')
    };

    if ($("#wifiauxpw1").val()!=$("#wifiauxpw2").val()) {
      $("#wifiauxpw1").css('background-color', '#fcc')
      $("#wifiauxpw2").css('background-color', '#fcc')
      this.pwverify3Target.innerHTML="✘";
      this.pwverify3Target.style.color="red";
    } else {
      this.pwverify3Target.innerHTML="✔";
      this.pwverify3Target.style.color="green";
      if (!problemfound) {
        $("#wifiauxpw1").css('background-color', 'white')
        $("#wifiauxpw2").css('background-color', 'white')
      };
    };

  }
}

