// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// no active storage needed! 
//require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
//require("jquery-ui-themes/themes/redmond/jquery-ui.min.css")
require("jquery-ui-themes/themes/base/jquery-ui.min.css")
require("bootstrap")

// initializes the index tab bar
$( function() {
  $( "#wumi_index_tabs" ).tabs();
} );

// invokes the modal bootstrap flash dialogs if inititally opened by browser
$( function() { $("#alertmodal").modal(); } );
$( function() { $("#noticemodal").modal(); } );
// invokes the modal bootstrap flash dialogs if reloaded/redirected by turbolinks
$(document).on("turbolinks:load", function() { $("#alertmodal").modal(); } );
$(document).on("turbolinks:load", function() { $("#noticemodal").modal(); } );
$(document).on("turbolinks:load", function() {$( "#wumi_index_tabs" ).tabs(); } );
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'
import 'css/site'
import 'bootstrap-icons/font/bootstrap-icons.css'
