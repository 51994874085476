import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["container"]

  connect() {
    // called when controller has connected all targets
    document.getElementById('csdataform').addEventListener (
    "submit",
      function (evt) {
        $("#csdataspinner").show();
      }
    );
  }



  retrieve(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
  }
}

