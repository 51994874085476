import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ["container","pwverify1","pwverify2","pwverify3","pwverify1label","pwverify2label","pwverify3label","unverifylabel","unverify"]

  retrieve(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
  }

  connect() {
    // called when controller has connected all targets
    document.getElementById('resetpasswdform').addEventListener (
    "submit",
      function (evt) {
        $("#resetpasswdspinner").show();
      }
    );

  }

  change_value_un() {
    var unregexp=/^[a-zA-Z]+[a-zA-Z0-9-]{1,99}$/
    if (!(unregexp.test($("#username").val()))) {
      $("#username").css('background-color', '#fcc')
      this.unverifyTarget.innerHTML="✘";
      this.unverifyTarget.style.color="red";
    } else {
      this.unverifyTarget.innerHTML="✔";
      this.unverifyTarget.style.color="green";
      $("#username").css('background-color', 'white')
    };
  }

  change_value() {
    var problemfound=false;

    var errorlist="";

    /* docs for this regexp can be found in the controller source */
    var pwregexp=/^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)[A-Za-z0-9#!@$%^&*()\-_+={}\[\]|\\:;"'<>,.?\/]{7,127}$/;
    if (!(pwregexp.test($("#pw1").val()))) {
      $("#pw1").css('background-color', '#fcc')
      this.pwverify1Target.innerHTML="✘";
      this.pwverify1Target.style.color="red";
    } else {
      this.pwverify1Target.innerHTML="✔";
      this.pwverify1Target.style.color="green";
      $("#pw1").css('background-color', 'white')
    };

    if (!(pwregexp.test($("#pw2").val()))) {
      $("#pw2").css('background-color', '#fcc')
      this.pwverify2Target.innerHTML="✘";
      this.pwverify2Target.style.color="red";
    } else {
      this.pwverify2Target.innerHTML="✔";
      this.pwverify2Target.style.color="green";
      $("#pw2").css('background-color', 'white')
    };

    if ($("#pw1").val()!=$("#pw2").val()) {
      $("#pw1").css('background-color', '#fcc')
      $("#pw2").css('background-color', '#fcc')
      this.pwverify3Target.innerHTML="✘";
      this.pwverify3Target.style.color="red";
    } else {
      this.pwverify3Target.innerHTML="✔";
      this.pwverify3Target.style.color="green";
      if (!problemfound) {
        $("#pw1").css('background-color', 'white')
        $("#pw2").css('background-color', 'white')
      };
    };

  }
}

