import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ["certmanchpwform","container", "certmanadv", "csrsbmt", "customcsr", "downloadcertform", "pwverify1label", "pwverify2label", "pwverify3label", "pwverify1", "pwverify2", "pwverify3"]

  connect() {
    $("#certmanadv").accordion({
      collapsible: false,
      icons: false,
      active: 0
    });

    var csrform=document.getElementById('certmancsrform')
    if (csrform) {
      csrform.addEventListener (
      "submit",
        function (evt) {
          $("#certmancsrspinner").show();
        }
      );
    };

    var csrpwform=document.getElementById('certmanchpwform')
    if (csrpwform) {
      csrpwform.addEventListener (
      "submit",
        function (evt) {
          $("#certmanpwspinner").show();
        }
      );
    };

  }

  download_cert() {
    $("#downloadcertform").submit();
  }

  download_key() {
    $("#downloadkeyform").submit();
  }

  download_enckey() {
    $("#downloadenckeyform").submit();
  }

  download_tcp_profile() {
    $("#downloadtcpprofileform").submit();
  }

  download_udp_profile() {
    $("#downloadudpprofileform").submit();
  }

  download_cachain() {
    $("#downloadcachainform").submit();
  }

  disableCSRSubmitBtn() {
    this.csrsbmtTarget.disabled = true;
  }

  collapseAdvCertman() {
    $("#collapseAdvancedCert").collapse('toggle');
  }

  retrieve(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.parentNode.innerHTML = xhr.response;
  }

  retrieveDlg(event) {
    const [data, status, xhr] = event.detail;
    // this.containerTarget.innerHTML = xhr.response;
    this.containerTarget.parentNode.insertAdjacentHTML('beforeend',xhr.response);
    $('#certman_chpasswddlg').modal();

    $('#certmanchpwform').on (
    "submit",
      function (evt) {
        $("#certmanpwspinner").show();
      }
    );
  }

  change_value() {
    var problemfound=false;

    var errorlist="";

    /* docs for this regexp can be found in the controller ruby source */
    var pwregexp=/^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)[A-Za-z0-9#!@$%^&*()\-_+={}\[\]|\\:;"'<>,.?\/]{10,127}$/;
    if (!(pwregexp.test($("#certpw1").val()))) {
      $("#certpw1").css('background-color', '#fcc')
      this.pwverify1Target.innerHTML="✘";
      this.pwverify1Target.style.color="red";
    } else {
      this.pwverify1Target.innerHTML="✔";
      this.pwverify1Target.style.color="green";
      $("#certpw1").css('background-color', 'white')
    };

    if (!(pwregexp.test($("#certpw2").val()))) {
      $("#certpw2").css('background-color', '#fcc')
      this.pwverify2Target.innerHTML="✘";
      this.pwverify2Target.style.color="red";
    } else {
      this.pwverify2Target.innerHTML="✔";
      this.pwverify2Target.style.color="green";
      $("#certpw2").css('background-color', 'white')
    };

    if ($("#certpw1").val()!=$("#certpw2").val()) {
      $("#certpw1").css('background-color', '#fcc')
      $("#certpw2").css('background-color', '#fcc')
      this.pwverify3Target.innerHTML="✘";
      this.pwverify3Target.style.color="red";
    } else {
      this.pwverify3Target.innerHTML="✔";
      this.pwverify3Target.style.color="green";
      if (!problemfound) {
        $("#certpw1").css('background-color', 'white')
        $("#certpw2").css('background-color', 'white')
      };
    };

  }


}

