import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["container"]

  checkAllCheckBoxes() {
    if (document.getElementById('approvalcb1').checked &
        document.getElementById('approvalcb2').checked &
        document.getElementById('approvalcb3').checked &
        document.getElementById('approvalcb4').checked &
        document.getElementById('approvalcb5').checked &
        document.getElementById('approvalcb6').checked)
    {
      document.getElementById('instquizsubmit').disabled=false;
      document.getElementById('instquiz_result_checkall_hint').hidden = true;
    } else {
      document.getElementById('instquizsubmit').disabled=true;
      document.getElementById('instquiz_result_checkall_hint').hidden = false;
    }
  }

  connect() {
    // called when controller has connected all targets
    document.getElementById('approvalcb1').addEventListener("click", this.checkAllCheckBoxes, false);
    document.getElementById('approvalcb2').addEventListener("click", this.checkAllCheckBoxes, false);
    document.getElementById('approvalcb3').addEventListener("click", this.checkAllCheckBoxes, false);
    document.getElementById('approvalcb4').addEventListener("click", this.checkAllCheckBoxes, false);
    document.getElementById('approvalcb5').addEventListener("click", this.checkAllCheckBoxes, false);
    document.getElementById('approvalcb6').addEventListener("click", this.checkAllCheckBoxes, false);
  }

}

