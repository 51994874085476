import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ["container","container2","username","uniid","passwd"]

/*  retrieve(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
  } */

  connect() {
    // called when controller has connected all targets
    document.getElementById('pwhomeusernameform').addEventListener (
    "submit",
      function (evt) {
        $("#pwresetunspinner").show();
      }
    );

    document.getElementById('pwhomeuniidform').addEventListener (
    "submit",
      function (evt) {
        $("#pwresetuniidspinner").show();
      }
    );

  }

  change_value_oben() {
  }

  change_val_unten() {
  }
}

